/**
 * @class XMLHttpRequest
 */
(function(window) {
    'use strict';

    if(window == null){return;}

    if ("response" in XMLHttpRequest.prototype ||
        "mozResponseArrayBuffer" in XMLHttpRequest.prototype ||
        "mozResponse" in XMLHttpRequest.prototype ||
        "responseArrayBuffer" in XMLHttpRequest.prototype){
        return;
    }

    /**
     * @memberOf XMLHttpRequest
     * @instance
     * @method response
     */
    Object.defineProperty(XMLHttpRequest.prototype, "response", {
        get: function() {
            return new Uint8Array( new VBArray(this.responseBody).toArray() );
        }
    });

})('undefined' !== typeof window ? window : null);