/**
 * @class Number
 */
;
(function (global, factory) {

    if (typeof exports === 'object' && typeof module != 'undefined') {
        module.exports = factory();
    } else if (typeof define === 'function' && define.amd) {
        define(factory);
    } else {
        global.Number = factory();
    }
})(this, (function () {
    'use strict';

    /**
     * 數字格式化
     *
     * @method
     *
     * @param {Number} [n=0] - 小數點位數，預設0
     * @param {Number} [x=3] - 分位數，預設使用千分位
     *
     * @return {String}
     *
     * @example
     * var a = 123456.78;
     *
     * a.format();      // 123,456
     * a.format(1);     // 123,456.7
     * a.format(2);     // 123,456.78
     * a.format(0, 4);  // 12,3456
     * a.format(0, -1); // 123456
     */
    Number.prototype.format = function (n, x) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
        return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    }

    /**
     * 數字前補上固定長度的字串
     *
     * @method
     *
     * @param {Number} w - 顯示的字串長度
     * @param {Number} c - 數字不足時，補足長度的前綴
     *
     * @returns {String}
     *
     * @example
     * var a = 123;
     *
     * a.pad(10, 0);    // 0000000123
     * a.pad(5, '#');   // ##123
     */
    Number.prototype.pad = function (w, c) {
        var n = this + '';
        c = c || '0';
        return n.length >= w ? n : new Array(w - n.length + 1).join(c) + n;
    }

    // 格式化数字输入为 K M T等单位
    Number.prototype.stringify = function (digits) {
        var num = this;
        digits = digits || 2;
        // 1E3是数学表达式10 ^ 3的表示法，它与1000相同。如果你写了一百万1E6将是1000000然后你将节省两个以上的字节
        var si = [{
                value: 1,
                symbol: ""
            },
            {
                value: 1E3,
                symbol: "K"
            },
            {
                value: 1E6,
                symbol: "M"
            },
            {
                value: 1E9,
                symbol: "G"
            },
            {
                value: 1E12,
                symbol: "T"
            },
            {
                value: 1E15,
                symbol: "P"
            },
            {
                value: 1E18,
                symbol: "E"
            }
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        // return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
        var number = Math.floor((num / si[i].value) * 100) / 100;
        return String(number).replace(rx, "$1") + si[i].symbol;
    }

    if (!Number.isFinite) {
        /**
         * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isFinite
         */
        Number.isFinite = function (value) {
            return typeof value === 'number' && isFinite(value);
        }
    }

    if (!Number.isNaN) {
        /**
         * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
         */
        Number.isNaN = function (value) {
            return typeof value === 'number' && isNaN(value);
        }
    }

    if (!Number.isInteger) {
        /**
         * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger
         */
        Number.isInteger = function (value) {
            return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
        }
    }

    return Number;
}));