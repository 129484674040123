;(function(global, factory){

    if(typeof exports === 'object' && typeof module != 'undefined'){
        module.exports = factory();
    }else if(typeof define === 'function' && define.amd){
        define(factory);
    }else{
        global.KeyCode = factory();
    }
})(this, (function(){
    'use strict';

    var KeyCode = {};

    KeyCode.CANCEL = 3;
    KeyCode.HELP = 6;
    KeyCode.BACK_SPACE = 8;
    KeyCode.TAB = 9;
    KeyCode.CLEAR = 12;
    KeyCode.RETURN = 13;
    KeyCode.ENTER = 14;
    KeyCode.SHIFT = 16;
    KeyCode.CONTROL = 17;
    KeyCode.ALT = 18;
    KeyCode.PAUSE = 19;
    KeyCode.CAPS_LOCK = 20;
    KeyCode.ESCAPE = 27;
    KeyCode.SPACE = 32;
    KeyCode.PAGE_UP = 33;
    KeyCode.PAGE_DOWN = 34;
    KeyCode.END = 35;
    KeyCode.HOME = 36;
    KeyCode.LEFT = 37;
    KeyCode.UP = 38;
    KeyCode.RIGHT = 39;
    KeyCode.DOWN = 40;
    KeyCode.PRINTSCREEN = 44;
    KeyCode.INSERT = 45;
    KeyCode.DELETE = 46;
    KeyCode.KEY_0 = 48;
    KeyCode.KEY_1 = 49;
    KeyCode.KEY_2 = 50;
    KeyCode.KEY_3 = 51;
    KeyCode.KEY_4 = 52;
    KeyCode.KEY_5 = 53;
    KeyCode.KEY_6 = 54;
    KeyCode.KEY_7 = 55;
    KeyCode.KEY_8 = 56;
    KeyCode.KEY_9 = 57;
    KeyCode.SEMICOLON = 59;
    KeyCode.EQUALS = 61;
    KeyCode.A = 65;
    KeyCode.B = 66;
    KeyCode.C = 67;
    KeyCode.D = 68;
    KeyCode.E = 69;
    KeyCode.F = 70;
    KeyCode.G = 71;
    KeyCode.H = 72;
    KeyCode.I = 73;
    KeyCode.J = 74;
    KeyCode.K = 75;
    KeyCode.L = 76;
    KeyCode.M = 77;
    KeyCode.N = 78;
    KeyCode.O = 79;
    KeyCode.P = 80;
    KeyCode.Q = 81;
    KeyCode.R = 82;
    KeyCode.S = 83;
    KeyCode.T = 84;
    KeyCode.U = 85;
    KeyCode.V = 86;
    KeyCode.W = 87;
    KeyCode.X = 88;
    KeyCode.Y = 89;
    KeyCode.Z = 90;
    KeyCode.CONTEXT_MENU = 93;
    KeyCode.NUMPAD0 = 96;
    KeyCode.NUMPAD1 = 97;
    KeyCode.NUMPAD2 = 98;
    KeyCode.NUMPAD3 = 99;
    KeyCode.NUMPAD4 = 100;
    KeyCode.NUMPAD5 = 101;
    KeyCode.NUMPAD6 = 102;
    KeyCode.NUMPAD7 = 103;
    KeyCode.NUMPAD8 = 104;
    KeyCode.NUMPAD9 = 105;
    KeyCode.MULTIPLY = 106;
    KeyCode.ADD = 107;
    KeyCode.SEPARATOR = 108;
    KeyCode.SUBTRACT = 109;
    KeyCode.DECIMAL = 110;
    KeyCode.DIVIDE = 111;
    KeyCode.F1 = 112;
    KeyCode.F2 = 113;
    KeyCode.F3 = 114;
    KeyCode.F4 = 115;
    KeyCode.F5 = 116;
    KeyCode.F6 = 117;
    KeyCode.F7 = 118;
    KeyCode.F8 = 119;
    KeyCode.F9 = 120;
    KeyCode.F10 = 121;
    KeyCode.F11 = 122;
    KeyCode.F12 = 123;
    KeyCode.F13 = 124;
    KeyCode.F14 = 125;
    KeyCode.F15 = 126;
    KeyCode.F16 = 127;
    KeyCode.F17 = 128;
    KeyCode.F18 = 129;
    KeyCode.F19 = 130;
    KeyCode.F20 = 131;
    KeyCode.F21 = 132;
    KeyCode.F22 = 133;
    KeyCode.F23 = 134;
    KeyCode.F24 = 135;
    KeyCode.NUM_LOCK = 144;
    KeyCode.SCROLL_LOCK = 145;
    KeyCode.COMMA = 188;
    KeyCode.PERIOD = 190;
    KeyCode.SLASH = 191;
    KeyCode.BACK_QUOTE = 192;
    KeyCode.OPEN_BRACKET = 219;
    KeyCode.BACK_SLASH = 220;
    KeyCode.CLOSE_BRACKET = 221;
    KeyCode.QUOTE = 222;
    KeyCode.META = 224;

    return KeyCode;
}));