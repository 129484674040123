/**
 * @class Date
 */
;
(function (global, factory) {

    if (typeof exports === 'object' && typeof module != 'undefined') {
        module.exports = factory();
    } else if (typeof define === 'function' && define.amd) {
        define(factory);
    } else {
        global.Date = factory();
    }
})(this, (function () {
    'use strict';

    /**
     * 預設的日期轉字串格式<code>"yyyy-MM-dd hh:mm:ss"</code>
     *
     * @typedef {String} Date.DEFAULT_PATTERN
     *
     * @constant
     */
    Date.DEFAULT_PATTERN = "yyyy-MM-dd hh:mm:ss";

    /**
     * 預設的日期轉字串格式<code>"yyyy-MM-dd hh:mm:ss"</code>
     *
     * @typedef {String} Date.DEFAULT_PATTERN
     *
     * @constant
     */
    Date.DEFAULT_COUNTDOWN_PATTERN = "hh:mm:ss";

    /**
     * 預設的時間格式，根據瀏覽器語系是否有zh來判斷是<code>['上午','下午']</code>或是<code>['AM','PM']</code>
     *
     * @typedef {Array} Date.DEFAULT_TIME
     *
     * @constant
     */
    Date.DEFAULT_TIME = typeof window != 'undefined' && window.navigator.language.indexOf("zh") != -1 ? ['上午', '下午'] : ['AM', 'PM'];

    /**
     * 預設的星期格式，根據瀏覽器語系是否有zh來判斷是<code>['日','一','二','三','四','五','六']</code>或是<code>['Sun','Mon','Tue','Wed','Thu','Fri','Sat']</code>
     *
     * @typedef {String} Date.DEFAULT_WEEK
     *
     * @constant
     */
    Date.DEFAULT_WEEK = typeof window != 'undefined' && window.navigator.language.indexOf("zh") != -1 ? ['日', '一', '二', '三', '四', '五', '六'] : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    /**
     * 格式化日期
     *
     * @method
     *
     * @param {String} [pattern=Date.DEFAULT_PATTERN] - Pattern
     *
     * @returns {String}
     *
     * @example
     * var d = new Date();
     * d.format();  // 2018-02-11 12:13:01
     */
    Date.prototype.format = function (pattern) {
        //  Declare
        var date = new Date(this);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var time = parseInt(date.getHours() / 12);
        var hour = date.getHours();
        var minute = date.getMinutes();
        var second = date.getSeconds();
        var week = date.getDay();
        var millisecond = date.getMilliseconds();

        //  Validate pattern
        pattern = pattern == null ? Date.DEFAULT_PATTERN : pattern;

        //  zero fill
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        hour = hour < 10 ? "0" + hour : hour;
        minute = minute < 10 ? "0" + minute : minute;
        second = second < 10 ? "0" + second : second;

        var formatedDate = pattern;
        // Mon Sep 17 2012 15:44:16 GMT+0800 (CST) to 2012-09-17 15:44:16
        formatedDate = formatedDate.replace("yyyy", year);
        formatedDate = formatedDate.replace("MM", month);
        formatedDate = formatedDate.replace("dd", day);
        formatedDate = formatedDate.replace("a", Date.DEFAULT_TIME[time]);
        formatedDate = formatedDate.replace("HH", hour > 13 ? hour % 12 : hour);
        formatedDate = formatedDate.replace("hh", hour);
        formatedDate = formatedDate.replace("mm", minute);
        formatedDate = formatedDate.replace("ss", second);
        formatedDate = formatedDate.replace("SSS", millisecond);
        formatedDate = formatedDate.replace("E", Date.DEFAULT_WEEK[week]);
        return formatedDate;
    }

    // 格式化日期为 xx分钟前、xx小时前、xx天前、xx年前
    Date.prototype.stringify = function () {
        var getDateDifferences = function(dt1, dt2) {
            return Math.floor(
                (Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate())) / (1000 * 3600 * 24));
        }

        var time = new Date();
        var last = this;
        var inactiveDays = getDateDifferences(time, last);
        var days = Math.abs(inactiveDays).format();

        if (days >= 30 && days < 365) {
            return Math.floor(days / 30) + "个月前"
        } else if (days < 30 && days >= 1) {
            return days + "天前"
        } else if (days > 365) {
            return "1年前"
        } else if (days == 0) {
            var now = new Date();
            // 相差的时间戳
            var runTime = parseInt((now.getTime() - last.getTime()) / 1000);

            var runMonthTime = runTime % (86400 * 365);
            var runDayTime = runMonthTime % (86400 * 30);
            var runHourTime = runDayTime % 86400;
            var runMinuteTime = runHourTime % 3600;
            var runSecondTime = runMinuteTime % 60;

            var hour = Math.floor(runHourTime / 3600);
            var minute = Math.floor(runMinuteTime / 60);
            var second = runSecondTime;

            if (hour > 0) {
                return hour + "小时前"
            } else if (hour == 0 && minute > 0) {
                return minute + "分钟前"
            } else if (hour == 0 && minute == 0) {
                return "刚刚"
            }
        }
    }

    /**
     * 倒數計時
     * 傳入剩餘毫秒數後，轉換成字串輸出，當剩餘毫秒數小於0秒時，預設輸出00:00:00
     *
     * @method
     *
     * @param {Number} timer -
     * @param {String} pattern - Pattern
     *
     * @returns {String}
     *
     * @deprecated since version 1.1.0
     */
    Date.countdown = function (timer, pattern) {
        //  ms to s
        timer /= 1000;
        //  Validate pattern
        pattern = pattern != undefined ? pattern : Date.DEFAULT_COUNTDOWN_PATTERN;

        var hour = parseInt(timer / 60 / 60 % 100);
        var minute = parseInt(timer / 60 % 60);
        var second = parseInt(timer % 60);

        pattern = pattern.replace("hh", hour);
        pattern = pattern.replace("mm", minute);
        pattern = pattern.replace("ss", second);
        return pattern;
    }

    /**
     * <h6>將時間戳格式化為字串</h6>
     * 傳入剩餘毫秒數後，轉換成字串輸出，當剩餘毫秒數小於0秒時，預設輸出00:00:00
     *
     * @method
     *
     * @param {Number} time - 時間戳
     * @param {String} [pattern=Date.DEFAULT_PATTERN] - Pattern
     *
     * @returns {String}
     */
    Date.format = function (time, pattern) {

        var hour = Math.floor(time / 1000 / 60 / 60);
        var minute = Math.floor((time / 1000 / 60 % 60));
        var second = Math.floor(time / 1000 % 60);
        var millisecond = Math.floor(time % 1000);
        pattern = pattern ? pattern : Date.DEFAULT_PATTERN;

        hour = hour < 10 ? "0" + hour : hour;
        minute = minute < 10 ? "0" + minute : minute;
        second = second < 10 ? "0" + second : second;

        pattern = pattern.replace("HH", hour > 13 ? hour % 12 : hour);
        pattern = pattern.replace("hh", hour);
        pattern = pattern.replace("mm", minute);
        pattern = pattern.replace("ss", second);
        pattern = pattern.replace("SSS", millisecond);

        return pattern;
    }

    /**
     * 判斷是否為Date物件
     *
     * @method
     *
     * @param {*} date - 要判斷是否為日期的物件
     *
     * @returns {boolean}
     */
    Date.isDate = function (date) {
        return Object.prototype.toString.call(date) === "[object Date]";
    }

    return Date;
}));