/**
 * @class Math
 */
;(function(global, factory){

    if(typeof exports === 'object' && typeof module != 'undefined'){
        module.exports = factory();
    }else if(typeof define === 'function' && define.amd){
        define(factory);
    }else{
        global.Math = factory();
    }
})(this, (function(){
    'use strict';

    /**
     * 亂數產生正整數
     *
     * @method
     *
     * @param {Number} n - 亂數產生的最大值
     *
     * @returns {Number}
     */
    Math.randomInt = function (n) {
        return Math.floor(Math.random() * n);
    }

    /**
     * <h6>階乘</h6>
     * <b>公式：</b>n!
     *
     * @method
     *
     * @param {Number} n - n階乘
     *
     * @return {Number}
     */
    Math.factorial = function(n) {
        return n == 0 || n == 1 ? 1 : n * Math.factorial(n - 1);
    }

    /**
     * <h6>排列</h6>
     * <b>公式：</b>P(n, r) = n! / (n − r)!
     *
     * @method
     *
     * @param {Number} n - n個元素
     * @param {Number} r - 從n個元素取出r個元素排列
     *
     * @return {Number}
     */
    Math.permutation = function (n, r) {
        if(n === 0 || r > n){
            return 0;
        }else{
            return Math.factorial(n) / Math.factorial(n - r);
        }
    }

    /**
     * <h6>組合</h6>
     * <b>公式：</b>C(n, n − r) = n! / (r! × (n − r)!)
     *
     * @method
     *
     * @param {Number} n - n個元素
     * @param {Number} r - 從n個元素取出r個元素組合
     *
     * @return {Number}
     */
    Math.combination = function (n, r) {
        if(n === 0 || r > n){
            return 0;
        }else{
            return Math.factorial(n) / (Math.factorial(r) * Math.factorial(n - r));
        }
    }

    /**
     * <h6>重複組合</h6>
     * <b>公式：</b>H(n, r) = C(n + r - 1, r)
     * 
     * @method
     *
     * @param {Number} n - 從r個元素分個n的組合
     * @param {Number} r - r個元素
     *
     * @return {Number}
     */
    Math.hCombination = function(n, r) {
        return Math.combination(n + r - 1, r);
    }
    
    return Math;
}));