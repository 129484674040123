;(function (global, factory) {

    if (typeof exports === 'object' && typeof module != 'undefined') {
        module.exports = factory();
    } else if (typeof define === 'function' && define.amd) {
        define(factory);
    } else {
        global.Set = factory();
    }
})(this, (function () {
    'use strict';

    if (new Set([0]).size === 0) {
        //constructor doesnt take an iterable as an argument - thanks IE
        var BuiltinSet = Set;

        /**
         * @class Set
         *
         * @param {Array} iterable - object
         *
         * @see https://stackoverflow.com/questions/45686278/polyfill-for-internet-explorer-ecmascript-set-constructor-to-allow-iterable-argu
         */
        Set = function Set(iterable) {
            var set = new BuiltinSet();
            if (iterable) {
                iterable.forEach(set.add, set);
            }
            return set;
        };
        Set.prototype = BuiltinSet.prototype;
        Set.prototype.constructor = Set;
    }

    return Set;
}));