;(function (global, factory) {

	if (typeof exports === 'object' && typeof module != 'undefined') {
		module.exports = factory();
	} else if (typeof define === 'function' && define.amd) {
		define(factory);
	} else {
		global.StepLogger = factory();
	}
})(this, (function () {
	'use strict';

	function StepLogger() {
		this._data = {};
	}

	StepLogger.prototype.getData = function (name) {
		return this._data[name];
	}

	StepLogger.prototype.start = function (name, comment) {
		try{
			this._data[name] = {
				start: Date.now(),
				end: 0,
				total: 0,
				comment: comment ? comment : ""
			}
		}catch(e){console.error(e);}
	}

	StepLogger.prototype.end = function (name) {
		try{
			if(this._data[name] != undefined){
				this._data[name].end = Date.now();
				this._data[name].total = this._data[name].end - this._data[name].start;
			}
		}catch(e){console.error(e);}
	}

	StepLogger.prototype.getMaxTime = function () {
		var max = 0;

		try{
			for(var key in this._data){
				max = Math.max(max, this._data[key].total);
			}
		}catch(e){
			console.error(e);
		}
		return max;
	}

	StepLogger.prototype.getTotalTime = function () {
		var total = 0;

		try{
			for(var key in this._data){
				total += this._data[key].total;
			}
		}catch(e){
			console.error(e);
		}
		return total;
	}

	StepLogger.prototype.toString = function (split) {
		split = split ? split : "\n";

		try{
			var array = [];
			var total = 0;

			for(var key in this._data){
				array.push(this._data[key].comment + ": " + (this._data[key].total / 1000) + "s");
			}
			return array.join("\n");
		}catch(e){
			console.error(e);
			return "";
		}
	}

	return StepLogger;
}));