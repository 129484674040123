/**
 * @class WSClient
 */
;(function(global, factory){

    if(typeof exports === 'object' && typeof module != 'undefined'){
        module.exports = factory();
    }else if(typeof define === 'function' && define.amd){
        define(factory);
    }else{
        global.WSClient = factory();
    }
})(this, (function(){
    'use strict';

    /**
     *
     * @memberOf WSClient
     *
     * @constructor
     *
     * @param {Object} option -
     */
    var WSClient = function(option){
        //  init
        var init = function(client, option){
            client.socket = null;
            client.isOnline = false;
            client.isUserClose = false;
            client.option = option || {};
        }

        var getWebSocket = function(host){
            var socket = null;

            if('WebSocket' in window){
                socket = new WebSocket(host);
            }else if('MozWebSocket' in window){
                socket = new MozWebSocket(host);
            }
            return socket;
        }

        /**
         * 連線
         *
         * @method connect
         * @instance
         * @memberOf WSClient
         *
         * @param host - 連線位址
         *
         * @returns {WSClient}
         */
        this.connect = function(host){
            var client = this,
                socket = getWebSocket(host);

            if(socket == null){
                throw new TypeError('错误: 当前浏览器不支持WebSocket，请更换其他浏览器');
            }

            socket.onopen = function(){
                var onopen = client.option.onopen;

                client.isOnline = true;
                onopen && onopen();
            };

            socket.onclose = function(){
                var onclose = client.option.onclose;

                client.isOnline = false;
                onclose && onclose();
                if(!client.isUserClose){
                    client.initialize(host);
                }
            };

            socket.onmessage = function(msg){
                var option = client.option;

                if(option.onmessage){
                    option.onmessage(msg);
                }
            };

            this.socket = socket;
            return this;
        }
        /**
         * 初始化(連線)
         *
         * @method initialize
         * @instance
         * @memberOf WSClient
         *
         * @param host - 連線位址
         *
         * @returns {WSClient}
         */
        this.initialize = function(host){
            return this.connect(host);
        }

        /**
         * 發送訊息
         *
         * @method sendMessage
         * @instance
         * @memberOf WSClient
         *
         * @param {String} type - 訊息分類
         * @param {json} data - 訊息
         */
        this.sendMessage = function(type, data){
            this.socket.send(JSON.stringify({type:type, data:data}));
        }

        /**
         * 發送二進制訊息
         *
         * @method sendBlob
         * @instance
         * @memberOf WSClient
         *
         * @param blob - 二進制訊息
         */
        this.sendBlob = function(blob){
            this.socket.send(blob);
        }

        /**
         * 關閉連線
         *
         * @method close
         * @instance
         * @memberOf WSClient
         */
        this.close = function(){
            this.isOnline = false;
            this.isUserClose = true;
            this.socket.close();
            return true;
        }

        init(this, option);
    }

    return WSClient;
}));