;(function(global, factory){

    if(typeof exports === 'object' && typeof module != 'undefined'){
        module.exports = factory();
    }else if(typeof define === 'function' && define.amd){
        define(factory);
    }else{
        Object.assign(global, factory());
    }
})(this, (function(){
    'use strict';

    var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    return {
        /**
         * 編碼中文字符
         *
         * @global
         *
         * @param {String} chars - 預編碼的字串
         *
         * @returns {String}
         */
        btoa: function(chars) {
            var buffer = "";
            var i, n;
            for (i = 0, n = chars.length; i < n; i += 3) {
                var b1 = chars.charCodeAt(i) & 0xFF;
                var b2 = chars.charCodeAt(i + 1) & 0xFF;
                var b3 = chars.charCodeAt(i + 2) & 0xFF;
                var d1 = b1 >> 2, d2 = ((b1 & 3) << 4) | (b2 >> 4);
                var d3 = i + 1 < n ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
                var d4 = i + 2 < n ? (b3 & 0x3F) : 64;
                buffer += digits.charAt(d1) + digits.charAt(d2) + digits.charAt(d3) + digits.charAt(d4);
            }
            return buffer;
        },
        /**
         * 跳脫字元的處理
         *
         * @global
         *
         * @param {String} unsafe - 預處理的字串
         *
         * @returns {XML|String}
         */
        escapeHtml: function(unsafe) {
            return unsafe
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        }
    }
}));