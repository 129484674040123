/**
 * @class TCGTool
 *
 * @description
 * TCG工具包，主要集成TCG工作時，經常使用到的方法
 */
;(function(global, factory){

    if(typeof exports === 'object' && typeof module != 'undefined'){
        module.exports = factory();
    }else if(typeof define === 'function' && define.amd){
        define(factory);
    }else{
        global.TCGTool = factory();
    }
})(this, (function(){
    'use strict';

    var si = setInterval,
        ci = clearInterval,
        intervalPool = [];

    /**
     * 瀏覽器資訊
     *
     * @typedef {Object} TCGTool.BrowserInfo
     * @property {String} screen - 螢幕解析度
     * @property {String} browser - 瀏覽器名稱
     * @property {String} browserVersion - 瀏覽器版本
     * @property {boolean} mobile - 是否為手機端
     * @property {String} os - 作業系統名稱
     * @property {String} osVersion - 作業系統版本
     * @property {boolean} cookies - 是否支持cookie
     * @property {String} flashVersion - Flash版本
     * @property {String} user_agent - navigator.userAgent
     */

    /**
     * 中斷資訊(Interval Info)
     *
     * @typedef {Object} TCGTool.IntervalInfo
     * @property {String} interval_id - intervalId
     * @property {String} desc - 描述該interval的用途
     * @property {function} func - function
     * @property {integer} delay - delay time
     * @property {number} start_time - 開始時間的時間戳
     */

    return {
        /**
         * 取得使用者瀏覽器的詳細資訊
         *
         * @method
         *
         * @memberOf TCGTool
         *
         * @return {TCGTool.BrowserInfo}
         */
        getBrowser: function () {
            var unknown = '-';

            // screen
            var screenSize = '';
            if (screen.width) {
                var width = (screen.width) ? screen.width : '';
                var height = (screen.height) ? screen.height : '';
                screenSize += '' + width + " x " + height;
            }

            // browser
            var nVer = navigator.appVersion;
            var nAgt = navigator.userAgent;
            var browser = navigator.appName;
            var version = '' + parseFloat(navigator.appVersion);
            var majorVersion = parseInt(navigator.appVersion, 10);
            var nameOffset, verOffset, ix;

            //  https://github.com/suibobuzhuliu/blog/issues/2

            //  世界之窗
            if ((verOffset = nAgt.indexOf('TheWorld')) != -1) {
                browser = 'TheWorld';
                version = nAgt.substring(verOffset + 9);
            }
            //  360
            else if (window.showModalDialog && window.chrome) {
                browser = '360se';
                verOffset = nAgt.indexOf('Chrome');
                version = nAgt.substring(verOffset + 7);
            }
            //  QQ瀏覽器(兼容模式)
            else if ((verOffset = nAgt.indexOf('QQBrowser')) != -1 && nAgt.indexOf('Gecko') != -1) {
                browser = 'QQBrowser';
                version = nAgt.substring(verOffset + 10);
            }
            //  QQ瀏覽器
            else if ((verOffset = nAgt.indexOf('QQBrowser')) != -1) {
                browser = 'QQBrowser';
                version = nAgt.substring(verOffset + 10);
            }
            //  UC
            else if ((verOffset = nAgt.indexOf('UBrowser')) != -1) {
                browser = 'UBrowser';
                version = nAgt.substring(verOffset + 9);
            }
            //  Sogou搜狗
            else if ((verOffset = nAgt.indexOf('SE 2.X MetaSr 1.0')) != -1) {
                browser = 'Sogou';
                verOffset = nAgt.indexOf('Chrome');
                version = nAgt.substring(verOffset + 7);
            }
            //  獵豹瀏覽器
            else if ((verOffset = nAgt.indexOf('LBBROWSER')) != -1) {
                browser = 'LBBrowser';
                verOffset = nAgt.indexOf('Chrome');
                version = nAgt.substring(verOffset + 7);
            }
            // Opera
            else if ((verOffset = nAgt.indexOf('Opera')) != -1) {
                browser = 'Opera';
                version = nAgt.substring(verOffset + 6);
                if ((verOffset = nAgt.indexOf('Version')) != -1) {
                    version = nAgt.substring(verOffset + 8);
                }
            }
            // Opera Next
            else if ((verOffset = nAgt.indexOf('OPR')) != -1) {
                browser = 'Opera';
                version = nAgt.substring(verOffset + 4);
            }
            // Edge
            else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
                browser = 'Microsoft Edge';
                version = nAgt.substring(verOffset + 5);
            }
            // MSIE
            else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
                browser = 'Microsoft Internet Explorer';
                version = nAgt.substring(verOffset + 5);
            }
            // Chrome
            else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
                browser = 'Chrome';
                version = nAgt.substring(verOffset + 7);
            }
            // Safari
            else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
                browser = 'Safari';
                version = nAgt.substring(verOffset + 7);
                if ((verOffset = nAgt.indexOf('Version')) != -1) {
                    version = nAgt.substring(verOffset + 8);
                }
            }
            // Firefox
            else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
                browser = 'Firefox';
                version = nAgt.substring(verOffset + 8);
            }
            // MSIE 11+
            else if (nAgt.indexOf('Trident/') != -1) {
                browser = 'Microsoft Internet Explorer';
                version = nAgt.substring(nAgt.indexOf('rv:') + 3);
            }
            // Other browsers
            else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
                browser = nAgt.substring(nameOffset, verOffset);
                version = nAgt.substring(verOffset + 1);
                if (browser.toLowerCase() == browser.toUpperCase()) {
                    browser = navigator.appName;
                }
            }
            // trim the version string
            if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
            if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
            if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

            majorVersion = parseInt('' + version, 10);
            if (isNaN(majorVersion)) {
                version = '' + parseFloat(navigator.appVersion);
                majorVersion = parseInt(navigator.appVersion, 10);
            }

            // mobile version
            var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

            // cookie
            var cookieEnabled = (navigator.cookieEnabled) ? true : false;

            if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
                document.cookie = 'testcookie';
                cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
            }

            // system
            var os = unknown;
            var clientStrings = [
                {s:'Windows 10', r:/(Windows 10.0|Windows NT 10.0)/},
                {s:'Windows 8.1', r:/(Windows 8.1|Windows NT 6.3)/},
                {s:'Windows 8', r:/(Windows 8|Windows NT 6.2)/},
                {s:'Windows 7', r:/(Windows 7|Windows NT 6.1)/},
                {s:'Windows Vista', r:/Windows NT 6.0/},
                {s:'Windows Server 2003', r:/Windows NT 5.2/},
                {s:'Windows XP', r:/(Windows NT 5.1|Windows XP)/},
                {s:'Windows 2000', r:/(Windows NT 5.0|Windows 2000)/},
                {s:'Windows ME', r:/(Win 9x 4.90|Windows ME)/},
                {s:'Windows 98', r:/(Windows 98|Win98)/},
                {s:'Windows 95', r:/(Windows 95|Win95|Windows_95)/},
                {s:'Windows NT 4.0', r:/(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
                {s:'Windows CE', r:/Windows CE/},
                {s:'Windows 3.11', r:/Win16/},
                {s:'Android', r:/Android/},
                {s:'Open BSD', r:/OpenBSD/},
                {s:'Sun OS', r:/SunOS/},
                {s:'Linux', r:/(Linux|X11)/},
                {s:'iOS', r:/(iPhone|iPad|iPod)/},
                {s:'Mac OS X', r:/Mac OS X/},
                {s:'Mac OS', r:/(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
                {s:'QNX', r:/QNX/},
                {s:'UNIX', r:/UNIX/},
                {s:'BeOS', r:/BeOS/},
                {s:'OS/2', r:/OS\/2/},
                {s:'Search Bot', r:/(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
            ];
            for (var id in clientStrings) {
                var cs = clientStrings[id];
                if (cs.r.test(nAgt)) {
                    os = cs.s;
                    break;
                }
            }

            var osVersion = unknown;

            if (/Windows/.test(os)) {
                osVersion = /Windows (.*)/.exec(os)[1];
                os = 'Windows';
            }

            switch (os) {
                case 'Mac OS X':
                    osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
                    break;

                case 'Android':
                    osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
                    break;

                case 'iOS':
                    osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                    osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
                    break;
            }

            // flash (you'll need to include swfobject)
            /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */
            var flashVersion = 'no check';
            if (typeof swfobject != 'undefined') {
                var fv = swfobject.getFlashPlayerVersion();
                if (fv.major > 0) {
                    flashVersion = fv.major + '.' + fv.minor + ' r' + fv.release;
                }
                else  {
                    flashVersion = unknown;
                }
            }

            return {
                screen: screenSize,
                browser: browser,
                browserVersion: version,
                browserMajorVersion: majorVersion,
                mobile: mobile,
                os: os,
                osVersion: osVersion,
                cookies: cookieEnabled,
                flashVersion: flashVersion,
                user_agent: navigator.userAgent
            };
        },
        /**
         * 與<code>setInterval()</code>的用法一樣，差別只在於多了一個desc的參數來描述這一個中斷的用途是什麼。並且可以透過{@link TCGTool.getIntervalPool}來查看目前網頁中，有多少中斷
         *
         * @method
         *
         * @memberOf TCGTool
         *
         * @param {string} desc - 描述該interval的用途
         * @param {function} func - function
         * @param {integer} delay - delay time
         *
         * @returns {number} interavlId
         */
        setInterval: function (desc, func, delay) {
            var interval_id = si(func, delay);

            intervalPool.push({
                interval_id: interval_id,
                desc: desc,
                func: func,
                delay: delay,
                start_time: Date.now()
            });
            return interval_id;
        },
        /**
         * 與<code>clearInterval()</code>的用法一樣，差別只在於還能清除存放在記錄在TCGTool的中斷池(interval pool)的記錄。
         *
         * @method
         *
         * @memberOf TCGTool
         *
         * @param {number} interavlId - intervalId
         *
         * @return {number} interavlId
         */
        clearInterval: function (intervalID) {

            for (var i = 0; i < intervalPool.length; i++) {
                if (intervalPool[i].interval_id == intervalID) {
                    intervalPool.splice(i, 1);
                    break;
                }
            }
            return ci(intervalID);
        },
        /**
         * 只要是透過{@link TCGTool.setInterval}設定的中斷，都可以透過該方法取得中斷池(Interval Pool)中，記錄的詳細資訊。
         *
         * @method
         *
         * @memberOf TCGTool
         *
         * @returns {Array.<TCGTool.IntervalInfo>}
         */
        getIntervalPool: function () {
            return intervalPool;
        },
        /**
         * 檢測瀏覽器是否支援Flash播放
         * 
         * @method
         *
         * @memberOf TCGTool
         * 
         * @return {boolean}
         */
        isSupportFlash: function () {
            var hasFlash = false;
            
            try{
                var fo = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
                if(fo) hasFlash = true;
            }catch(e){
                if(navigator.mimeTypes ["application/x-shockwave-flash"] != undefined) hasFlash = true;
            }
            return hasFlash;
        }
    }
}));