/**
 * @class String
 */
;(function(global, factory){

    if(typeof exports === 'object' && typeof module != 'undefined'){
        module.exports = factory();
    }else if(typeof define === 'function' && define.amd){
        define(factory);
    }else{
        global.String = factory();
    }
})(this, (function(){
    'use strict';

    /**
     * 全部替換字串
     *
     * @param {String} search - 要取代的字串
     * @param {String} replacement - 取代後的字串
     *
     * @returns {String}
     */
    String.prototype.replaceAll = function(search, replacement){
        var target = this;
        return target.replace(new RegExp(search, 'g'), replacement);
    }

    /**
     * 判斷是否空值
     *
     * @param {?String} s - 預檢核的物件
     *
     * @returns {boolean}
     */
    String.isEmpty = function(s){
        if(s == null || s == undefined){
            return true;
        }else if(typeof s === "string" && s.trim() == ""){
            return true;
        }else{
            return false;
        }
    }

    /**
     * 判斷是否非空值
     *
     * @param s - string
     * @returns {boolean}
     */
    String.isNotEmpty = function(s){
        return !this.isEmpty(s);
    }

    /**
     * 隨機產生一組亂數字串
     *
     * @param length
     * @returns {string}
     */
    String.random = function(length){
        return new Array(length ? length : 5).join().replace(/(.|$)/g, function(){return ((Math.random()*36)|0).toString(36)[Math.random()<.5?"toString":"toUpperCase"]();});
    }

    /**
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
     */
    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function(searchString, position){
            return this.substr(position || 0, searchString.length) === searchString;
        };
    }

    return String;
}));