/**
 * <p>Script管理器，可透過ScriptManager動態加載*.js，並判斷是否己加載過了，而不會造成重覆加載的情況</p>
 * <p>也可透過ScriptManager預加載所需要的*.js</p>
 *
 * @class ScriptManager
 * @version 1.2.0
 */
;(function(global, factory){

    if(typeof exports === 'object' && typeof module != 'undefined'){
        module.exports = factory();
    }else if(typeof define === 'function' && define.amd){
        define(factory);
    }else{
        global.ScriptManager = factory();
    }
})(this, (function(){
    'use strict';

    return {
        _preload_list: [],
        _allow_preload: true,
        /**
         * 加載js，透過該方法可以協助管理js的加載。只要src路徑名稱一樣，就不會再重複加載了
         *
         * @static
         * @method ScriptManager.load
         *
         * @param {string} src - 加載*.js的路徑
         * @param {function} success - 加載成功後的callback
         * @param {function} error - 加載失敗後的callback
         *
         * @version 1.2.0
         */
        load: function (src, success, error) {
            var _this = this;

            if(this.isLoaded(src)){
                success ? success() : null;
            }else{
                this.importJS(src, function () {
                    success ? success() : null;
                }, function () {
                    error ? error() : null;
                });
            }
        },
        /**
         * 加載js
         *
         * @static
         * @method ScriptManager.importJS
         *
         * @param {string} src - 加載*.js的路徑
         * @param {function} success - 加載成功後的callback
         * @param {function} error - 加載失敗後的callback
         *
         * @version 1.2.0
         */
        importJS: function (src, success, error) {
            var script = document.createElement("script"); script.type = "text/javascript"; script.src = src; script.async = true;

            if(script.readyState){
                script.onreadystatechange = function(){ (script.readyState == "loaded" || script.readyState == "complete") && success ? success() : null;}
            }else if(success){
                script.onload = success; script.onerror = error;
            }
            document.head.appendChild(script);
        },
        /**
         * 開始預加載
         *
         * @static
         * @method ScriptManager.startPreload
         *
         * @version 1.2.0
         */
        startPreload: function () {
            var _this = this;
            var callback = function () {
                var src;

                if(!_this._allow_preload){return;}

                while(true){
                    src = _this._preload_list.shift();

                    if(src == undefined){
                        break;
                    }if(!_this.isLoaded(src)){
                        _this.importJS(src, callback, callback);
                        break;
                    }
                }
            };

            if(this._preload_list.length == 0)return;

            this._allow_preload = true;

            callback();
        },
        /**
         * 停止預加載
         *
         * @static
         * @method ScriptManager.stopPreload
         *
         * @version 1.2.0
         */
        stopPreload: function () {
            this._allow_preload = false;
        },
        /**
         * 是否己透過<code>load</code>加載過了
         *
         * @static
         * @method ScriptManager.isLoaded
         *
         * @param src - 加載*.js的路徑
         *
         * @return {boolean} true己加載、false未加載
         *
         * @version 1.2.0
         */
        isLoaded: function (src) {
            return document.querySelector("script[src='" + src + "']") != null;
        },
        /**
         * 透過<code>addPreload</code>可以將希望預加載的路徑存放進ScriptManager
         *
         * @static
         * @method ScriptManager.addPreload
         *
         * @param {string|array} data - 希望預加載的js路徑，接受字串或陣列
         *
         * @version 1.2.0
         */
        addPreload: function (data) {
            if(Array.isArray(data)){
                this._preload_list = this._preload_list.concat(data);
            }else{
                this._preload_list.push(data);
            }
        }
    }
}));

