/**
 * @class Array
 */
;(function(global, factory){

    if(typeof exports === 'object' && typeof module != 'undefined'){
        module.exports = factory();
    }else if(typeof define === 'function' && define.amd){
        define(factory);
    }else{
        global.Array = factory();
    }
})(this, (function(){
    'use strict';

    /**
     * 透過{@link Array.indexOf}比較陣列裡的元件是否相同，若相同則移除
     *
     * @method
     *
     * @param {*} obj - 要移除的物件
     *
     * @returns {Array}
     */
    Array.prototype.remove = function(obj){
        var index = this.indexOf(obj);
        return index != -1 ? this.splice(index, 1) : this;
    }

    /**
     * <h6>插入資料</h6>
     * 將資料指定預插入的索引
     * 
     * @param {integer} [index=0] - 插入索引
     * @param {*} obj - 插入的資料
     */
    Array.prototype.insert = function(index, obj){
        this.splice(!index ? 0 : index, 0, obj);
    }

    /**
     * <h6>計算陣列元素總合</h6>
     * 若陣列裡存放的數值是Number，則透過{@link sum}能快速計算陣列裡數值的總合
     *
     * @method
     *
     * @param {integer} [index=0]  - 開始索引
     * @param {integer} [length=this.length] - 計算總和的長度
     *
     * @returns {Number}
     *
     * @example
     * var array = [10, 11, 12, 13]
     * array.sum();     // 46
     */
    Array.prototype.sum = function(index, length){
        var sum = 0;

        index = index != null ? index : 0;
        length = length != null ? length : this.length;

        for(var i = index; i < index+length; i++){
            sum += this[i] * 1;
        }
        return sum;
    }

    /**
     * <h6>計算陣列元素，其屬性的總合</h6>
     * 陣列內存放物件，計算物件屬性的總合
     *
     * @method
     *
     * @param {String} key - 物件要加總的欄位名稱
     * @param {integer} [index=0] - 開始索引
     * @param {integer} [length=this.length] - 計算總和的長度
     *
     * @returns {Number}
     *
     * @example
     * var cart = [];
     *
     * cart.push({order_no:"AAE001", amount: 5});
     * cart.push({order_no:"AAE001", amount: 6});
     * cart.push({order_no:"AAE001", amount: 3});
     *
     * cart.summary();  // 14
     */
    Array.prototype.summary = function(key, index, length){
        var sum = 0;

        index = index != null ? index : 0;
        length = length != null ? length : this.length;

        for(var i = index; i < index+length; i++){
            sum += this[i][key] * 1;
        }
        return sum;
    }

    /**
     * 將陣列亂數重新排序，達到類似洗牌的效果
     *
     * @method
     *
     * @return {Array}
     */
    Array.prototype.shuffle = function(){
        var counter = this.length;

        while(counter > 0){
            var index = Math.floor(Math.random() * counter--);
            var temp = this[counter];

            this[counter] = this[index];
            this[index] = temp;
        }
        return this;
    }

    if(!Array.prototype.indexOf){
        /**
         * @method
         *
         * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/indexOf
         */
        Array.prototype.indexOf = function (searchElement /*, fromIndex */ ) {
            "use strict";
            if (this == null) {
                throw new TypeError();
            }
            var t = Object(this);
            var len = t.length >>> 0;
            if (len === 0) {
                return -1;
            }
            var n = 0;
            if (arguments.length > 0) {
                n = Number(arguments[1]);
                if (n != n) { // shortcut for verifying if it's NaN
                    n = 0;
                } else if (n != 0 && n != Infinity && n != -Infinity) {
                    n = (n > 0 || -1) * Math.floor(Math.abs(n));
                }
            }
            if (n >= len) {
                return -1;
            }
            var k = n >= 0 ? n : Math.max(len - Math.abs(n), 0);
            for (; k < len; k++) {
                if (k in t && t[k] === searchElement) {
                    return k;
                }
            }
            return -1;
        }
    }

	if (!Array.prototype.map) {
		/**
		 * @method
		 *
		 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/map
		 */
		Array.prototype.map = function(callback/*, thisArg*/) {

			var T, A, k;

			if (this == null) {
				throw new TypeError('this is null or not defined');
			}

			// 1. Let O be the result of calling ToObject passing the |this|
			//    value as the argument.
			var O = Object(this);

			// 2. Let lenValue be the result of calling the Get internal
			//    method of O with the argument "length".
			// 3. Let len be ToUint32(lenValue).
			var len = O.length >>> 0;

			// 4. If IsCallable(callback) is false, throw a TypeError exception.
			// See: http://es5.github.com/#x9.11
			if (typeof callback !== 'function') {
				throw new TypeError(callback + ' is not a function');
			}

			// 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
			if (arguments.length > 1) {
				T = arguments[1];
			}

			// 6. Let A be a new array created as if by the expression new Array(len)
			//    where Array is the standard built-in constructor with that name and
			//    len is the value of len.
			A = new Array(len);

			// 7. Let k be 0
			k = 0;

			// 8. Repeat, while k < len
			while (k < len) {

				var kValue, mappedValue;

				// a. Let Pk be ToString(k).
				//   This is implicit for LHS operands of the in operator
				// b. Let kPresent be the result of calling the HasProperty internal
				//    method of O with argument Pk.
				//   This step can be combined with c
				// c. If kPresent is true, then
				if (k in O) {

					// i. Let kValue be the result of calling the Get internal
					//    method of O with argument Pk.
					kValue = O[k];

					// ii. Let mappedValue be the result of calling the Call internal
					//     method of callback with T as the this value and argument
					//     list containing kValue, k, and O.
					mappedValue = callback.call(T, kValue, k, O);

					// iii. Call the DefineOwnProperty internal method of A with arguments
					// Pk, Property Descriptor
					// { Value: mappedValue,
					//   Writable: true,
					//   Enumerable: true,
					//   Configurable: true },
					// and false.

					// In browsers that support Object.defineProperty, use the following:
					// Object.defineProperty(A, k, {
					//   value: mappedValue,
					//   writable: true,
					//   enumerable: true,
					//   configurable: true
					// });

					// For best browser support, use the following:
					A[k] = mappedValue;
				}
				// d. Increase k by 1.
				k++;
			}

			// 9. return A
			return A;
		};
	}

    /**
     * 對陣列元素進行排列組合
     *
     * @method
     *
     * @param {Array} arr - array
     * @param {integer} size - arr的所有长度为size的子数组的组合
     *
     * @returns {Array}
     *
     * @see https://github.com/N-ZOO/everycode/issues/8
     *
     * @example
     * var array = [1,2,3,4];
     * Array.groupSplit(array, 2);  // [[1,2], [1,3], [1,4], [2,3], [2,4], [3,4]]
     */
    Array.groupSplit = function (arr, size) {
        var r = []; //result

        function _(t, a, n) { //tempArr, arr, num
            if (n === 0) {
                r[r.length] = t;
                return;
            }
            for (var i = 0, l = a.length - n; i <= l; i++) {
                var b = t.slice();
                b.push(a[i]);
                _(b, a.slice(i + 1), n - 1);
            }
        }
        _([], arr, size);
        return r;
    }

    if (!Array.isArray) {
        /**
         * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/isArray
         */
        Array.isArray = function(arg) {
            return Object.prototype.toString.call(arg) === '[object Array]';
        }
    }

    return Array;
}));